<template>
  <app-side-menu>
    <aside
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Rego: {{ entityName }}
        </span>
      </p>
      <ul
        v-if="innerValue"
        class="menu-list">
        <li>
          <div class="field">
            <label class="label">Theme</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="innerValue.theme">
                  <option
                    v-for="(theme , index) in themes"
                    :value="theme"
                    :key="index">{{ theme }}</option>
                </select>
              </div>
            </div>
          </div>
          <span class="buttons has-addons">
            <span
              class="button"
              :class="{ 'is-success' : innerValue.lineWrapping }"
              @click="toggleLineWrapping">
              <span class="icon">
                <i class="mdi mdi-wrap" />
              </span>
              <span>Wrap</span>
            </span>
          </span>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { CodemirrorThemes } from '@/components/VueCodemirror'

export default {
  name: 'EstimageMessageViewSideMenu',
  components: {
    AppSideMenu
  },
  props: {
    value: null,
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      themes: null,
      innerValue: null
    }
  },
  computed: {},
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'innerValue.theme': function(newVal, oldVal) {
      this.$setCodemirrorTheme(newVal)
    }
  },
  created() {
    this.themes = CodemirrorThemes
    this.innerValue = this.value
  },
  mounted() {},
  methods: {
    toggleLineWrapping() {
      this.innerValue.lineWrapping = !this.innerValue.lineWrapping
    }
  }
}
</script>
